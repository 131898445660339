<template>
    <v-card outlined flat>
        <v-card-text class="pa-2">
            <span class="d-block subtitle-1 text--primary">{{ value.titulo }}</span>
            <v-divider class="my-1" />
            <span class="d-block subtitle-2 font-weight-regular text--primary">Cod. {{ value.cod_incidente }}</span>
            <span class="d-block text--primary">Fecha de Registro: {{ value.fecha_incidente | moment("L") }}</span>
            <v-chip color="indigo" dark><v-icon left>account_circle</v-icon>{{ value.persona_autor }}</v-chip>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ['value']
}
</script>